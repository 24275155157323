<template>
  <div class="DomainInspect">
    <div class="container-fluid">
      <b-row>
        <b-col>
          <h1><span v-if="$route.params.fqdn === '$'">.</span><span
              v-if="$route.params.fqdn !== '$'">{{ $route.params.fqdn }}</span> <small class="text-muted">
            {{ $t('system.fqdn') }}</small>
          </h1>
        </b-col>
        <b-col>
          <div v-if="fqdn" class="float-right" style="margin-bottom: 1.5rem">
            <b-button-group>
              <b-button variant="outline-secondary" @click="$bvModal.show('evlog_fqdn')">
                {{ $t('system.event_log') }}
                <netvs-icon icon="evlog"></netvs-icon>
              </b-button>
              <b-button variant="outline-primary" @click="editFQDN">
                {{ $t('system.utils.update_template', {object: $t('system.fqdn')}) }}
                <netvs-icon icon="edit"></netvs-icon>
              </b-button>
              <b-dropdown @click="deleteFqdn(false, false)" split variant="outline-danger">
                <template #button-content>
                  {{ $t('system.utils.delete_template', {object: $t('system.fqdn')}) }}
                  <netvs-icon icon="delete"></netvs-icon>
                </template>
                <b-dropdown-item @click="deleteFqdn(true, false)">
                  {{ $t('components.fqdn_table.delete_fqdn_and_parent_empty_fqdns') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="fqdn.has_rr" @click="deleteFqdn(false, true)">
                  {{ $t('components.fqdn_table.delete_fqdn_and_referencing_dns_records') }}
                </b-dropdown-item>
                <b-dropdown-item v-if="fqdn.has_rr" @click="deleteFqdn(true, true)">
                  {{ $t('components.fqdn_table.all_of_the_above') }}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown variant="outline-secondary">
                <template v-slot:button-content>
                  <netvs-icon icon="info"></netvs-icon>
                </template>
                <b-dropdown-item :to="'/dnsvs/fqdns/' + fqdn.value + '/records'">
                  {{ $tc('components.fqdn_table.record_to_fqdn', 2) }}
                </b-dropdown-item>
                <b-dropdown-item :to="'/dnsvs/fqdns/' + fqdn.value + '/references'">
                  {{ $tc('components.fqdn_table.reference_to_fqdn', 2) }}
                </b-dropdown-item>
              </b-dropdown>
            </b-button-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <FQDNTable :fqdns="fqdns"></FQDNTable>
    <DBEditor :presets="db_editor_presets" modal_id="edit_inspected_fqdn"
              :object_title="object_title"
              :non_optionals_order="['value', 'description', 'type']"
              :input_reducer="edit_fqdn_reducer"
              object_function="update" object_fq_name="dns.fqdn" :old_data="db_editor_old_data"
              :nullable_non_expert="['description']">
    </DBEditor>
    <EVLogViewer v-if="fqdn" modal_id="evlog_fqdn" :title="'FQDN \''+ fqdn.value + '\''"
                 ref_obj_fq="dns.fqdn" refobj_id_field="value_list" :refobj_id_value="[fqdn.value]"></EVLogViewer>
  </div>
</template>

<script>
import FQDNService from '@/api-services/dns_fqdn.service'
import FQDNServiceRaw from '@/api-services.gen/dns.fqdn'
import FQDNTable from '@/components/FQDNTable.vue'
import EVLogViewer from '../../components/EVLogViewer'
import DBEditor from '../../components/db-editor/APIObjectDBEditor.vue'
import FQDNTypeService from '@/api-services.gen/dnscfg.fqdn_type'
import transactionutil from '@/util/transactionutil'

export default {
  name: 'DomainInspect',
  data() {
    return {
      fqdn: null,
      fqdns: null,
      object_title: null,
      db_editor_function: 'edit',
      db_editor_presets: {},
      edit_fqdn_reducer: {},
      db_editor_old_data: {}
    }
  },
  components: {DBEditor, EVLogViewer, FQDNTable},
  watch: {
    $route() {
      this.fqdns = null
      this.fetch()
    }
  },
  async created() {
    const response = await FQDNTypeService.list(this.$store.state, {sorting_params_list: ['position']})
    const types = response.data[0]
    const selections = {}
    for (const t of types) {
      let disp_name = t.description
      disp_name += ' ['
      disp_name += t.is_nonterminal ? this.$t('system.non_terminal') : this.$t('system.terminal')
      disp_name += ']'
      selections[t.name] = {display_name: disp_name}
    }
    this.edit_fqdn_reducer = {
      type: selections
    }
    await this.fetch()
  },
  methods: {
    deleteFqdn: function (do_del_pqdn, force_del_ref_records) {
      const itc = {}
      Object.assign(itc, this.fqdn)
      itc.do_del_pqdn = do_del_pqdn
      itc.force_del_ref_records = force_del_ref_records
      const ta = transactionutil.generateDeleteElement('dns.fqdn', FQDNServiceRaw.deleteParamsList(), itc,
        this.$t('system.fqdn') + ' ' + this.fqdn.value +
          (do_del_pqdn ? this.$t('components.fqdn_table.delete_parent_fqdn') : '') +
          (force_del_ref_records ? this.$t('components.fqdn_table.fqdn_delete_dns_records') : '')
      )
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
    editFQDN: function () {
      this.db_editor_old_data = this.fqdn
      this.db_editor_presets = this.fqdn
      this.object_title = this.fqdn.value
      this.$root.$emit('bv::show::modal', 'edit_inspected_fqdn')
    },
    async fetch() {
      this.fqdns = null
      let real_fqdn = this.$route.params.fqdn
      if (real_fqdn === '$') {
        real_fqdn = '.'
      }
      const response = await FQDNService.getByParent(this.$store.state, real_fqdn)
      this.fqdns = response.data.fqdn_list
      if (response.data.parent_fqdn.length === 0) {
        await this.$router.push('/404')
        return
      }
      this.fqdn = response.data.parent_fqdn[0]
      if (this.fqdn) {
        this.$store.commit('setNavigationRefreshHandle', {gpk: this.fqdn.gpk, objType: 'dns.fqdn'})
      }
    }
  }
}
</script>

<style scoped>

</style>
